import React from 'react';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { NumericFormatField } from '../../Forms/Fields';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';

export function MarketInvestmentsAccordion() {
  const { isMarketInvestmentsAccordionFilled } = useAssetAdvisoryAccordions();

  return (
    <Accordion.Item className="shadow-none" eventKey="0">
      <Accordion.Button className={clsx(isMarketInvestmentsAccordionFilled && 'bg-success-light')}>
        Inv. Mercados Capitales
      </Accordion.Button>

      <Accordion.Body className="d-flex flex-column gap-2">
        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="clp_capital_markets_investment"
          path="clp_capital_markets_investment"
          label="¿Cuánto tienes en Mercado de Capitales nacionales o internacionales?"
          prefix="CLP "
          placeholder="CLP"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="usd_capital_markets_investment"
          path="usd_capital_markets_investment"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="clp_alt_assets"
          path="clp_alt_assets"
          label="¿Cuánto tienes en Activos Alternativos nacionales o internacionales?"
          prefix="CLP "
          placeholder="CLP"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="usd_alt_assets"
          path="usd_alt_assets"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="apv"
          path="apv"
          label="¿Cuánto tienes en APV?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="other_investments"
          path="other_investments"
          label="¿Algún activo o inversión que no te haya preguntado?"
          prefix="$ "
          placeholder="$"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
